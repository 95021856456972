import React from "react";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/Layout";
import "../styles/post.scss";
import "gitalk/dist/gitalk.css";
import GitalkComponent from "gitalk/dist/gitalk-component";
import SEO from "../components/SEO";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.locale("zh-cn");

const Tags = ({ tags }) => {
  return (
    <section id="tags" className="py-10">
      <h3>Categorized under</h3>
      <ul className="flex gap-3 flex-wrap">
        {tags.map((tag, index) => {
          return (
            <li className="before:content-['#'] before:text-blue-500" key={index}>
              <Link to={`/tags/${tag}/`} key={index}>
                {tag}
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

const PostTemplate = ({ data }) => {
  const post = data.mdx;
  const { previous, next } = data;
  const { datePublished, tags } = post.frontmatter;
  const timeToRead = `${post.timeToRead}`;
  const timeFromNow = dayjs(datePublished).fromNow();
  const diffTime = dayjs().diff(datePublished, "month");
  const tipText =
    diffTime > 3 ? ` ⋅ 该文发布于 ${timeFromNow}，文中内容可能已过时` : "";
  const infoCaption = `发表于 ${datePublished} | 阅读时长 ≈ ${timeToRead}分钟 | ${timeFromNow}`;

  const isBrowser = () => typeof window !== "undefined";

  return (
    <Layout>
      <SEO title={post.frontmatter.title + ""} />
      <article className="mx-auto pt-10 prose dark:prose-invert">
        <section id="title">
          <h1>{post.frontmatter.title}</h1>
          <span>{post.frontmatter.description}</span>
        </section>
        <section id="stats">
          {infoCaption}
          {tipText && <div className="p-2 bg-gray-200 rounded">{tipText}</div>}
        </section>
        <section id="content">
          <MDXRenderer>{post.body}</MDXRenderer>
        </section>
        {tags && tags.length > 0 && <Tags tags={tags} />}
      </article>
      <nav>
        <ul className="flex justify-between">
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      {isBrowser() && (
        <GitalkComponent
          options={{
            clientID: "9a2cf402a6a687f66b7e",
            clientSecret: "8b1b0b64c27dccb093c0b43d1d04a0cde7719b67",
            repo: "wasdjkl.com",
            owner: "wasdjkl",
            admin: ["wasdjkl"],
            id: post.fields.slug,
            title: post.frontmatter.title
          }}
        />
      )}
    </Layout>
  );
};
export default PostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        description
        datePublished(formatString: "YYYY-MM-DD HH:mm:ss")
        dateModified(formatString: "YYYY-MM-DD HH:mm:ss")
        tags
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
